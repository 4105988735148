<template>
    <div class="wrap">
        <div class="header">header</div>
        <div class="main">
            <div style="height: 300%">弹性滚动区域</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "demo"
    }
</script>

<style>
    /*设置html和body的高度为显示屏的高度*/
    html, body {
        height: 100%;
        margin: 0;
    }

    .wrap {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        /*布局方向是垂直的*/
        flex-direction: column;
        width: 100%;
        height: 100%;
    }
    /*设置顶部和底部的高度*/
    .header, .footer {
        height: 40px;
        line-height: 40px;
        background-color: #D8D8D8;
        text-align: center;
    }
    /*设置高度是跟父元素的高度一致，100%；*/
    /*实际高度是 100% 减去顶部高度和底部高度，左右两边固定，中间是剩余100%原理一致*/
    .main {
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        width: 100%;
        overflow: auto;
    }
</style>